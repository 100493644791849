import React from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import LogoIcon from '../../svg/LogoIcon';
import Button from '../Button';

const Header = () => (
  <header className="sticky top-0 bg-gray-100 shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-40 mr-3">
          <Link to="/">
            <LogoIcon />
          </Link>
        </div>
      </div>
      <div className="flex justify-around w-full mt-4 sm:mt-0">
        <AnchorLink className="px-4" to="/#features">
          Features
        </AnchorLink>
        <AnchorLink className="px-4" to="/#howitworks">
          How It Works
        </AnchorLink>
        <AnchorLink className="px-4" to="/#stats">
          Stats
        </AnchorLink>
      </div>

      <div className="hidden md:block">
        {/* <Link className="" to="/login">
          Login
        </Link> */}
        <Button size="sm" className=" ml-4" to="/sign-up">
          Get Invited
        </Button>
      </div>
    </div>
  </header>
);

export default Header;
