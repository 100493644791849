import React from 'react';
import { Link } from 'gatsby';

const sizes = {
  default: `py-3 px-8`,
  sm: `py-1 px-3`,
  lg: `py-4 px-12`,
  xl: `py-5 px-16 text-lg`
};

const Button = ({ children, className = '', size, to }) => {
  return (
    <Link to={to}>
      <button
        type="button"
        className={`
        ${sizes[size] || sizes.default}
        ${className}
        bg-primary
        hover:bg-primary-darker
        rounded
        text-white
        font-bold
        tracking-wider
    `}
      >
        {children}
      </button>
    </Link>
  );
};

export default Button;
