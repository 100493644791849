import React from 'react';

const LogoIcon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 213 44"
    enable-background="new 0 0 213 44"
  >
    <g>
      <path
        fill="#0283FD"
        d="M64.4,18.21c-1.29,0.96-2.93,1.55-4.9,1.79c1.41,0.12,2.47,0.53,3.18,1.24c0.71,0.71,1.06,1.63,1.06,2.78
		c0,0.44-0.07,0.99-0.22,1.63l-0.49,2.16c-0.06,0.29-0.09,0.5-0.09,0.62c0,0.38,0.09,0.67,0.26,0.86c0.18,0.19,0.43,0.29,0.75,0.29
		c0.62,0,1.07,0.09,1.35,0.26c0.28,0.18,0.38,0.5,0.29,0.97c-0.12,0.68-0.47,1.34-1.06,1.99c-0.59,0.65-1.42,1.18-2.49,1.61
		c-1.07,0.43-2.38,0.64-3.9,0.64c-1.94,0-3.49-0.43-4.63-1.3c-1.15-0.87-1.72-2.11-1.72-3.73c0-0.62,0.09-1.28,0.26-1.99l0.71-2.82
		c0.12-0.38,0.18-0.78,0.18-1.19c0-0.68-0.23-1.16-0.68-1.46c-0.46-0.29-1.18-0.44-2.18-0.44h-1.68l-2.03,7.32
		c-0.06,0.18-0.09,0.4-0.09,0.66c0,0.41,0.1,0.77,0.31,1.06c0.21,0.29,0.49,0.6,0.84,0.93c0.26,0.21,0.47,0.4,0.62,0.6
		c0.15,0.19,0.22,0.39,0.22,0.6c0,0.5-0.24,0.85-0.71,1.06c-0.47,0.21-1.16,0.31-2.07,0.31H34.39c-0.77,0-1.34-0.14-1.72-0.42
		c-0.38-0.28-0.51-0.67-0.4-1.17c0.06-0.27,0.17-0.48,0.33-0.64c0.16-0.16,0.4-0.35,0.73-0.55c0.5-0.29,0.9-0.6,1.19-0.93
		c0.29-0.32,0.53-0.82,0.71-1.5l5.38-19.02c0.12-0.38,0.18-0.75,0.18-1.1c0-0.32-0.05-0.6-0.15-0.82c-0.1-0.22-0.24-0.48-0.42-0.77
		c-0.26-0.38-0.4-0.69-0.4-0.93c0-0.09,0.03-0.25,0.09-0.49c0.12-0.38,0.38-0.65,0.77-0.82c0.4-0.16,1.01-0.24,1.83-0.24h14.78
		c2.03,0,3.8,0.31,5.32,0.93c1.51,0.62,2.68,1.46,3.49,2.54c0.81,1.07,1.21,2.29,1.21,3.64c0,0.62-0.09,1.24-0.26,1.85
		C66.57,15.92,65.69,17.25,64.4,18.21z M55.97,12.23c0-1.56-0.85-2.34-2.56-2.34h-1.85l-1.94,7.81h1.85c1.15,0,2.05-0.28,2.71-0.84
		c0.66-0.56,1.18-1.59,1.57-3.09C55.9,13.1,55.97,12.58,55.97,12.23z"
      />
      <path
        fill="#0283FD"
        d="M89.9,12.87c1.68,1.19,2.52,2.71,2.52,4.57c0,0.56-0.06,1.07-0.18,1.54c-0.44,1.94-1.67,3.33-3.68,4.17
		c-2.02,0.84-4.39,1.26-7.13,1.26c-1.38,0-2.68-0.09-3.88-0.26c-0.03,1.53,0.38,2.6,1.24,3.22c0.85,0.62,2.04,0.93,3.57,0.93
		c0.71,0,1.38-0.08,2.03-0.24c0.65-0.16,1.47-0.4,2.47-0.73c0.82-0.29,1.38-0.44,1.68-0.44c0.32,0,0.49,0.19,0.49,0.57
		c0.03,1.03-0.35,2.11-1.15,3.24c-0.79,1.13-1.97,2.1-3.53,2.89c-1.56,0.79-3.44,1.19-5.65,1.19c-3.27,0-5.94-0.82-8.03-2.47
		c-2.09-1.65-3.13-4.04-3.13-7.19c0-0.88,0.12-1.91,0.35-3.09c0.73-3.62,2.35-6.35,4.83-8.19c2.49-1.84,5.51-2.76,9.07-2.76
		C85.52,11.08,88.22,11.68,89.9,12.87z M78.34,21.54c1.24,0,2.18-0.57,2.82-1.72c0.65-1.15,0.96-2.43,0.93-3.84
		c-0.03-0.82-0.27-1.24-0.71-1.24c-0.32,0-0.71,0.26-1.15,0.77c-0.44,0.52-0.88,1.29-1.3,2.32c-0.43,1.03-0.79,2.25-1.08,3.66
		C77.97,21.53,78.13,21.54,78.34,21.54z"
      />
      <path
        fill="#0283FD"
        d="M115.93,34.64c-0.24,0.85-0.35,1.44-0.35,1.76c0,0.38,0.09,0.65,0.29,0.79c0.19,0.15,0.51,0.22,0.95,0.22
		c0.38,0,0.84-0.05,1.39-0.16c0.54-0.1,0.89-0.15,1.04-0.15c0.35,0,0.53,0.24,0.53,0.71c0,0.74-0.44,1.51-1.32,2.34
		c-0.88,0.82-2.03,1.51-3.44,2.05c-1.41,0.54-2.84,0.82-4.28,0.82c-2.12,0-3.75-0.43-4.9-1.3c-1.15-0.87-1.72-2.08-1.72-3.64
		c0-0.79,0.12-1.56,0.35-2.29l1.19-3.88c-1.82,1.91-3.99,2.87-6.49,2.87c-1.32,0-2.54-0.32-3.64-0.95s-1.97-1.57-2.6-2.82
		c-0.63-1.25-0.95-2.74-0.95-4.48c0-1.44,0.18-2.82,0.53-4.15c0.62-2.44,1.58-4.49,2.89-6.16c1.31-1.66,2.82-2.9,4.54-3.71
		c1.72-0.81,3.51-1.21,5.36-1.21c1.74,0,3.2,0.25,4.39,0.75c1.19,0.5,2.26,1.27,3.2,2.29l1.28-1.06c0.62-0.53,1.21-0.92,1.76-1.17
		c0.56-0.25,1.24-0.38,2.03-0.38h1.99c0.73,0,1.3,0.15,1.7,0.46c0.4,0.31,0.6,0.74,0.6,1.3c0,0.15-0.06,0.46-0.18,0.93L115.93,34.64
		z M109.45,19.55l0.26-0.84c0.15-0.47,0.22-0.96,0.22-1.46c0-0.97-0.44-1.46-1.32-1.46c-0.74,0-1.43,0.46-2.07,1.37
		c-0.65,0.91-1.34,2.56-2.07,4.94c-0.53,1.62-0.79,3.03-0.79,4.24c0,0.91,0.15,1.59,0.46,2.03c0.31,0.44,0.71,0.66,1.21,0.66
		c0.38,0,0.72-0.17,1.02-0.51c0.29-0.34,0.57-0.88,0.84-1.61L109.45,19.55z"
      />
      <path
        fill="#0283FD"
        d="M147.83,28.55c-0.06,0.24-0.09,0.41-0.09,0.53c0,0.21,0.05,0.37,0.16,0.49c0.1,0.12,0.24,0.18,0.42,0.18
		c0.35,0,0.65-0.15,0.9-0.46s0.42-0.46,0.51-0.46c0.15,0,0.27,0.1,0.38,0.29c0.1,0.19,0.15,0.42,0.15,0.68
		c0.03,0.82-0.26,1.64-0.88,2.45c-0.62,0.81-1.49,1.48-2.6,2.01c-1.12,0.53-2.37,0.79-3.75,0.79c-1.74,0-3.12-0.35-4.15-1.06
		c-1.03-0.71-1.69-1.63-1.99-2.78c-1.06,1.18-2.23,2.07-3.51,2.67c-1.28,0.6-2.58,0.9-3.9,0.9c-2.06,0-3.79-0.51-5.18-1.52
		c-1.4-1.01-2.1-2.43-2.1-4.26c0-0.82,0.15-1.68,0.44-2.56l2.82-9.22c0.09-0.26,0.13-0.49,0.13-0.66c0-0.32-0.07-0.58-0.22-0.77
		c-0.15-0.19-0.37-0.39-0.66-0.6c-0.29-0.24-0.5-0.44-0.62-0.62c-0.12-0.18-0.13-0.41-0.04-0.71c0.18-0.68,1.1-1.27,2.78-1.79
		s3.43-0.77,5.25-0.77c1.71,0,2.96,0.32,3.75,0.97c0.79,0.65,1.19,1.53,1.19,2.65c0,0.68-0.1,1.32-0.31,1.94l-2.78,9.18
		c-0.12,0.32-0.18,0.63-0.18,0.93c0,0.41,0.12,0.72,0.35,0.93c0.24,0.21,0.54,0.31,0.93,0.31c0.56,0,1.01-0.16,1.37-0.49
		c0.35-0.32,0.71-0.87,1.06-1.63l2.69-8.87c0.09-0.26,0.13-0.49,0.13-0.66c0-0.32-0.07-0.58-0.22-0.77
		c-0.15-0.19-0.35-0.39-0.62-0.6c-0.29-0.24-0.5-0.44-0.62-0.62c-0.12-0.18-0.13-0.41-0.04-0.71c0.15-0.68,1.06-1.27,2.74-1.79
		s3.43-0.77,5.25-0.77c1.71,0,2.96,0.32,3.77,0.97c0.81,0.65,1.21,1.52,1.21,2.6c0,0.56-0.12,1.22-0.35,1.99L147.83,28.55z"
      />
      <path
        fill="#0283FD"
        d="M178.08,12.01c0.84,0.47,1.5,1.07,1.99,1.81c0.49,0.74,0.73,1.5,0.73,2.29c0,1.12-0.31,2.08-0.93,2.89
		c-0.62,0.81-1.6,1.61-2.96,2.4l-9.35,5.56c0.88,1,1.75,1.5,2.6,1.5c0.68,0,1.28-0.25,1.81-0.75c0.53-0.5,1.13-1.22,1.81-2.16
		c0.41-0.56,0.72-0.96,0.93-1.19c0.21-0.24,0.41-0.35,0.62-0.35c0.53,0,1.11,0.19,1.74,0.57c0.63,0.38,1.18,0.94,1.63,1.68
		c0.46,0.74,0.68,1.62,0.68,2.65c0,1.06-0.27,2.04-0.82,2.93c-0.54,0.9-1.31,1.61-2.29,2.14c-0.99,0.53-2.14,0.79-3.46,0.79
		c-1.29,0-2.38-0.23-3.27-0.68c-0.88-0.46-1.57-0.96-2.05-1.52c-0.49-0.56-0.96-1.22-1.43-1.99c-0.38-0.68-0.68-1.01-0.88-1.01
		c-0.18,0-0.36,0.1-0.55,0.31c-0.19,0.21-0.46,0.56-0.82,1.06c-0.79,1.15-1.61,2.07-2.45,2.78c-0.84,0.71-1.93,1.06-3.29,1.06
		c-1.29,0-2.46-0.24-3.51-0.71c-1.04-0.47-1.87-1.09-2.47-1.88c-0.6-0.78-0.9-1.62-0.9-2.54c0-1.62,1.07-3.1,3.22-4.46l11.34-6.93
		c-0.5-0.62-0.94-1.06-1.32-1.32c-0.38-0.26-0.79-0.4-1.24-0.4c-0.56,0-1.04,0.2-1.43,0.6c-0.4,0.4-0.8,0.95-1.21,1.65
		c-0.29,0.47-0.54,0.82-0.73,1.06c-0.19,0.24-0.4,0.35-0.64,0.35c-0.91,0-1.71-0.32-2.38-0.97c-0.68-0.65-1.01-1.53-1.01-2.65
		c0-0.91,0.23-1.77,0.68-2.58c0.46-0.81,1.1-1.46,1.94-1.96c0.84-0.5,1.82-0.75,2.93-0.75c1.62,0,2.88,0.39,3.77,1.17
		c0.9,0.78,1.7,1.74,2.41,2.89c0.41,0.65,0.69,0.97,0.84,0.97s0.32-0.1,0.51-0.29c0.19-0.19,0.35-0.38,0.49-0.57
		c0.13-0.19,0.24-0.33,0.33-0.42c0.88-1.15,1.74-2.06,2.56-2.74c0.82-0.68,1.87-1.02,3.13-1.02
		C176.34,11.3,177.24,11.54,178.08,12.01z"
      />
      <path
        fill="#0283FD"
        d="M207.6,12.87c0.78,1.04,1.17,2.46,1.17,4.26c0,1.56-0.28,3.24-0.84,5.03c-1.09,3.62-2.83,7.03-5.23,10.24
		c-2.4,3.21-5.15,5.78-8.25,7.72s-6.2,2.91-9.29,2.91c-1.53,0-2.85-0.26-3.95-0.79c-1.1-0.53-1.94-1.24-2.51-2.12
		c-0.57-0.88-0.86-1.82-0.86-2.82c0-0.44,0.07-0.93,0.22-1.46c0.26-0.82,0.71-1.46,1.32-1.9c0.62-0.44,1.35-0.66,2.21-0.66
		c1.35,0,2.46,0.46,3.33,1.39c0.87,0.93,1.42,2.2,1.66,3.82c1.88-0.09,3.62-0.9,5.21-2.43c-1.5-0.26-2.61-0.77-3.33-1.52
		s-1.21-1.92-1.48-3.51l-1.76-10.81c-0.09-0.56-0.21-0.95-0.38-1.17c-0.16-0.22-0.38-0.33-0.64-0.33c-0.21,0-0.45,0.1-0.73,0.31
		c-0.28,0.21-0.48,0.31-0.6,0.31c-0.24,0-0.35-0.25-0.35-0.75c0-0.41,0.07-0.85,0.22-1.32c0.53-1.68,1.51-3.1,2.96-4.26
		c1.44-1.16,3.12-1.74,5.03-1.74c1.88,0,3.27,0.56,4.17,1.68c0.9,1.12,1.48,2.84,1.74,5.16l1.32,9.71c0.09,0.74,0.35,1.1,0.79,1.1
		c0.53,0,1.15-0.48,1.85-1.43c0.71-0.96,1.4-2.32,2.07-4.08c-0.88-0.79-1.56-1.71-2.03-2.74c-0.47-1.03-0.71-2.13-0.71-3.31
		c0-0.91,0.12-1.73,0.35-2.47c0.35-1.18,0.9-2.07,1.65-2.67c0.75-0.6,1.6-0.9,2.54-0.9C205.78,11.3,206.82,11.83,207.6,12.87z"
      />
    </g>
    <path
      fill="#0283FD"
      d="M30.33,3.2L18.8,33.98c-0.49,1.32-2.45,1-2.49-0.41l-0.35-12.36c-0.04-1.53-2.24-1.72-2.54-0.22l-1.95,9.55
	c-0.23,1.12-1.7,1.41-2.33,0.45L2.18,20.53c-0.39-0.59-0.23-1.39,0.36-1.78L28.42,1.68C29.46,1,30.76,2.04,30.33,3.2z"
    />
  </svg>
);

export default LogoIcon;
